/** @format */

import React, { useEffect,  useState } from "react";
import "../section.css";
import "./landing.css";
import { Flex, Image, Text, Button, Link } from "@chakra-ui/react";
import Navbar from "../Navbar";
import Mobilenavbar from "../Mobilenavbar";
import TGLogo from "../../../assets/images/HomePage/TG-logo.webp";
import DesktopLoopVideo from "../../../assets/images/HomePage/DesktopLoop.mp4";
import MobileLoopVideo from "../../../assets/images/HomePage/MobileLoop1.mp4";

function LandingSection(props) {
  // for mobile and desktop video
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const videoSource = isMobile ? MobileLoopVideo : DesktopLoopVideo;

  return (
    // New Landing Page
    <Flex id="homepage">
      <div className="mainvideoLanding">
        <Link href="/">
          <Image
            objectFit={"contain"}
            // className=" animate__animated animate__slideInDown animate__delay"
            zIndex={5}
            position={"absolute"}
            ml={{ base: 0, md: "10px", lg: "40px" }}
            mt={{ base: "10px", md: "10px", lg: "10px" }}
            p={4}
            height={{
              base: "90px",
              md: "100px",
              lg: "100px",
            }}
            cursor={"pointer"}
            src={TGLogo}
            alt={"TrackGenesis Logo"}
          />
        </Link>

        <Navbar />

        <Mobilenavbar />

        {/* TTI card */}
        <Flex
          width={{ base: "100%", md: "100%", lg: "100%" }}
          ml={{ base: "0", md: "0", lg: "0%" }}
          color={"white"}
          className="fontheading heading "
          position={"absolute"}
          zIndex={2}
          mt={{ base: "63vh", md: "63vh", lg: "52vh" }}
          flexDir={"column"}
        >
          <Flex
            className="animate__animated animate__slower animate__fadeInLeft  animate__delay-1s"
            borderTopRightRadius={{ base: "5px", md: "8px", lg: "10px" }}
            borderBottomRightRadius={{ base: "5px", md: "8px", lg: "10px" }}
            py={{ base: "2", md: "2", lg: "1" }}
            px={5}
            borderRight={"2px solid #B989DD"}
            width={{ base: "65%", md: "50%", lg: "45%" }}
            backgroundColor={"rgba(0, 0, 0, 0.24)"}
          >
            <Text
              ml={"auto"}
              fontSize={{ base: "20px", md: "25px", lg: "35px" }}
              className="glow"
              fontWeight={"light"}
            >
              Transparency
            </Text>
          </Flex>

          <Flex
            className="animate__animated animate__slower animate__fadeInLeft  animate__delay-2s"
            mt={{ base: "10px", md: "15px", lg: "40px" }}
            borderTopRightRadius={{ base: "5px", md: "8px", lg: "10px" }}
            borderBottomRightRadius={{ base: "5px", md: "8px", lg: "10px" }}
            py={{ base: "2", md: "2", lg: "1" }}
            px={5}
            borderRight={"2px solid #B989DD"}
            width={{ base: "65%", md: "55%", lg: "48%" }}
            backgroundColor={"rgba(0, 0, 0, 0.24)"}
          >
            <Text
              ml={"auto"}
              fontSize={{ base: "20px", md: "25px", lg: "35px" }}
              className="glow"
              fontWeight={"light"}
            >
              Trust
            </Text>
          </Flex>

          <Flex
            className="animate__animated animate__slower animate__fadeInLeft  animate__delay-3s"
            mt={{ base: "10px", md: "15px", lg: "40px" }}
            borderTopRightRadius={{ base: "5px", md: "8px", lg: "10px" }}
            borderBottomRightRadius={{ base: "5px", md: "8px", lg: "10px" }}
            px={5}
            py={{ base: "2", md: "2", lg: "1" }}
            borderRight={"2px solid #B989DD"}
            width={{ base: "65%", md: "60%", lg: "55%" }}
            backgroundColor={"rgba(0, 0, 0, 0.24)"}
          >
            <Text
              ml={"auto"}
              fontSize={{ base: "20px", md: "25px", lg: "35px" }}
              className="glow"
              fontWeight={"light"}
              // display={{ base: "flex", md: "flex", lg: "flex" }}
            >
              Innovation
            </Text>
          </Flex>
          <Flex
            // border={"solid red"}
            width={"fit-content"}
            mx={"auto"}
            className="animate__animated animate__slower animate__fadeIn "
            mt={{ base: "0px", md: "1px", lg: "10px" }}
            px={5}
            py={4}
            // width={{ base: "65%", md: "60%", lg: "51%" }}
          >
            <Link
              ml={"auto"}
              _hover={{
                textDecoration: "none",
              }}
              href="https://ar.trackgenesis.com/"
            >
              <Button
                borderRadius={"10px"}
                marginLeft={{ base: "auto", md: "auto", lg: "0px" }}
                marginRight={{ base: "auto", md: "auto", lg: "0px" }}
                display="block"
                width={"100px"}
                height="30px"
                fontSize={{ base: "12px", md: "13px", lg: "13px" }}
                id="ARbtn"
                className="ar-button"
              >
                {" "}
                View AR 
              </Button>
            </Link>
          </Flex>
        </Flex>

        <video
          width="600"
          height="100%"
          autoPlay
          loop
          muted
          playsInline
          src={videoSource}
          className="videolanding animate__animated animate__fast animate__fadeIn"
        ></video>
      </div>
    </Flex>
  );
}

export default LandingSection;
